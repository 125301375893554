/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import UserContext from 'contexts/UserContextContainer/UserContext';
import AppContext from 'contexts/AppContext/AppContext';
import Loader from 'styleguide/components/Loader/Loader';
import { getUsersSignOut } from 'api/userAuthentication';
import { Status } from 'libs/utils/api/types';
import toasts from 'utils/toast';
import { clickSignUpPdp } from 'api/gtm';
import { isProductPage } from 'libs/utils/pageUtils';

const userNavigation = [
  { name: 'Orders', href: '/account' },
  { name: 'Address Book', href: '/account/addresses' },
  { name: 'Payments', href: '/account/credit_cards' },
  { name: 'Tax Exemptions', href: '/account/tax_exemptions' },
  { name: 'Settings', href: '/account/settings' },
];

const AccountMenu = () => {
  const appContext = React.useContext(AppContext);
  const { logOut, currentUser, isInitialMount } = React.useContext(UserContext);
  const location = useLocation();

  return (
    <div className="relative ml-4 flex-shrink-0">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              data-cy="accountMenuPopoverBtn"
              className={`
                ${
                  open ? 'text-gray-300' : 'text-default'
                } no-underline' group inline-flex cursor-pointer items-center bg-shades-0 transition duration-300 ease-in-out hover:text-gray-300`}
            >
              <span data-cy="accountManagementNavBtn" className="paragraph-medium-mobile">
                {(currentUser?.firstName && `Hi ${currentUser.firstName}!`) || 'Account'}
              </span>
              <ChevronDownIcon
                className={`${open ? '-rotate-180' : ''}
                  ml-1 h-4 w-4 transform text-default transition duration-150 ease-in-out group-hover:text-gray-300 ui-open:text-gray-300`}
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10  mt-1 -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                {({ close }) => (
                  <div>
                    <div className="overflow-hidden rounded-lg shadow-lg">
                      {!isInitialMount ? (
                        currentUser ? (
                          <div className="relative flex w-44 flex-col items-center bg-shades-0 font-hvMedium">
                            {userNavigation.map(item => (
                              <Popover.Button
                                as={RouterLink}
                                key={item.name}
                                to={item.href}
                                className="w-44 no-underline"
                                data-cy={item.name}
                              >
                                <div className="paragraph-mobile my-4 px-4 text-default transition duration-300 ease-in-out hover:text-gray-300">
                                  {item.name}
                                </div>
                              </Popover.Button>
                            ))}
                            <RouterLink
                              data-cy="accountMenuLogoutBtn"
                              to="/users/sign-out"
                              className="w-44 border-t border-gray-50 no-underline"
                              onClick={e => {
                                e.preventDefault();
                                getUsersSignOut()
                                  .then(res => {
                                    if (res.status === Status.Ok) {
                                      logOut();
                                      toasts.create('Successfully signed out.', 'info');
                                    }
                                  })
                                  .finally(() => close());
                              }}
                            >
                              <div className="my-4 px-4 text-sm text-default transition duration-300 ease-in-out hover:text-gray-300">
                                Sign Out
                              </div>
                            </RouterLink>
                          </div>
                        ) : (
                          <div className="relative flex w-[190px] flex-col items-start bg-shades-0 font-hvMedium">
                            <Popover.Button
                              as={RouterLink}
                              data-cy="accountMenuSignInBtn"
                              to="/sign-in"
                              className="w-full border-b border-gray-50 px-4 py-4 pl-3 font-hvMedium
                                  text-sm text-default no-underline transition duration-300 ease-in-out hover:text-gray-300"
                            >
                              Sign In
                            </Popover.Button>
                            <div className="paragraph-medium-mobile relative my-4 pl-3 text-gray-500">
                              New customer?
                            </div>
                            <Popover.Button
                              as={RouterLink}
                              to={{
                                pathname: '/sign-up',
                                state: { from: location.pathname },
                              }}
                              className="mb-3 ml-3 mt-1 rounded-lg border-2 border-solid border-shades-0 bg-orange px-8 py-3
                                              font-hvMedium text-sm text-shades-0
                                              no-underline transition duration-300 ease-in-out hover:bg-orange-900"
                              onClick={() => {
                                if (isProductPage(location.pathname, appContext.store.cmsPages)) {
                                  clickSignUpPdp();
                                }
                              }}
                            >
                              Sign Up
                            </Popover.Button>
                          </div>
                        )
                      ) : (
                        <Loader size="sm" />
                      )}
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default AccountMenu;
