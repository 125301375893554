import { RadioGroup } from '@headlessui/react';
import cn from 'classnames';
import React, { ReactNode } from 'react';
import { IconCheckmark } from 'styleguide/icons';

interface Props {
  value: string | number;
  key: string;
  label: string | ReactNode;
  className?: string;
}

const RadioGroupButton = ({ value, label, className, ...other }: Props) => {
  const getContainerClasses = (active, checked) =>
    cn(
      active && 'ring-2 ring-blue ring-offset-2',
      checked
        ? 'border-transparent bg-blue text-shades-0 hover:bg-blue-900'
        : 'border-gray-200 bg-shades-0 text-neutral-900 hover:bg-gray-50',
      'flex h-8 cursor-pointer items-center rounded-md border px-2 py-1 justify-between font-hvLite text-xs uppercase sm:flex-1',
      typeof label !== 'string' &&
        'h-auto bg-white hover:bg-white !text-default border-gray-50 hover:!border-blue-900',
      checked && typeof label !== 'string' && '!bg-blue !text-white !pl-0',
      className,
    );

  return (
    <RadioGroup.Option
      value={value}
      key={other.key}
      className={({ active, checked }) => getContainerClasses(active, checked)}
    >
      <RadioGroup.Label
        className="p-1 flex w-full justify-between"
        as={typeof label !== 'string' ? 'div' : 'p'}
      >
        {label}
        {typeof label !== 'string' && <IconCheckmark color="white" size="sm" className="self-end" />}
      </RadioGroup.Label>
    </RadioGroup.Option>
  );
};

export default RadioGroupButton;
