import * as React from 'react';
import classNames from 'classnames';

interface Props {
  type?: 'button' | 'submit';
  className?: string | null;
  onClick?: (Event) => void;
  setRef?: (ref: HTMLButtonElement) => void;
}

/**
 * This component is intended to render custom UI element, which needs functionality of <button />
 * (focus on Tab, fire onClick handler on Enter/Space key press etc), but has its own custom look.
 */
const ButtonLike = ({ type = 'button', className, onClick, setRef, ...otherProps }: Props) => {
  let domNode;
  return (
    <button
      {...otherProps}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classNames(
        'mx-2 cursor-pointer appearance-none border-0 bg-none px-1 pb-1 pt-px',
        className,
      )}
      ref={ref => {
        domNode = ref;
        if (setRef) {
          setRef(ref);
        }
      }}
      onClick={event => {
        if (onClick) {
          onClick(event);
        }
        if (domNode) {
          domNode.blur();
        } // Getting rid of focus outline after the click
      }}
    />
  );
};

export default ButtonLike;
