import { BasicOrderCheckout, PurchaseOrder } from './orders/types';

export interface GtmProduct {
  item_name: string;
  item_id: string;
}

export interface GtmProductWithPrice extends GtmProduct {
  price: number;
  quantity: number;
}

export interface GtmDataLayerValue {
  event: string;
  ecommerce?: {
    category?: string;
    items?: GtmProduct[];
    properties?: Record<string, unknown>;
  };
  eventCallback?: () => void;
}

declare global {
  interface Window {
    dataLayer: GtmDataLayerValue[];
  }
}

const push = (data: GtmDataLayerValue) => {
  try {
    window.dataLayer.push(data);

    return true;
  } catch (err) {
    return false;
  }
};

export const productPage = (category: string, items: GtmProduct[]): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'view_product_page',
      ecommerce: {
        category,
        items,
      },
    };

    push(data);

    resolve();
  });

export const formSubmit = (event: string): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event,
    };

    push(data);

    resolve();
  });

export const productSelection = (category: string, item: GtmProduct): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'view_item',
      ecommerce: {
        category,
        items: [item],
      },
    };

    push(data);

    resolve();
  });

export const addToCart = (item: GtmProductWithPrice): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'add_to_cart',
      ecommerce: {
        items: [item],
      },
    };

    push(data);

    resolve();
  });

export const removeFromCart = (item: GtmProductWithPrice): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'remove_from_cart',
      ecommerce: {
        items: [item],
      },
    };

    push(data);

    resolve();
  });

export const checkout = (orderFields: BasicOrderCheckout, items: GtmProductWithPrice[]): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'checkout',
      ecommerce: {
        ...orderFields,
        items,
      },
      eventCallback: null,
    };

    push(data);

    resolve();
  });

export const purchase = (orderFields: PurchaseOrder, items: GtmProductWithPrice[]): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'purchase',
      ecommerce: {
        ...orderFields,
        items,
      },
    };

    push(data);

    resolve();
  });

export const clickCarousel = (item: GtmProduct, properties: Record<string, unknown>): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'carousel_click',
      ecommerce: {
        ...properties,
        items: [item],
      },
    };

    push(data);

    resolve();
  });
export const clickRelatedProduct = (item: GtmProduct): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'click_related_product',
      ecommerce: {
        items: [item],
      },
    };

    push(data);

    resolve();
  });
export const clickSecondaryNavbar = (properties: Record<string, unknown>): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'click_secondary_navbar',
      ecommerce: {
        ...properties,
      },
    };

    push(data);

    resolve();
  });
export const clickTemplate = (properties: Record<string, unknown>): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'template_click',
      ecommerce: {
        ...properties,
      },
    };

    push(data);

    resolve();
  });
export const clickFAQ = (properties: Record<string, unknown>): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'faq_click',
      ecommerce: {
        ...properties,
      },
    };

    push(data);

    resolve();
  });
export const clickRefreshQuote = (properties: Record<string, unknown>): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'refresh_quote_click',
      ecommerce: {
        ...properties,
      },
    };

    push(data);

    resolve();
  });
export const clickAddToCart = (properties: Record<string, unknown>): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'add_to_cart_click',
      ecommerce: {
        ...properties,
      },
    };

    push(data);

    resolve();
  });
export const clickEmailUs = (properties: Record<string, unknown>): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'email_us_click',
      ecommerce: {
        ...properties,
      },
    };

    push(data);

    resolve();
  });
export const clickGraphicDesignLink = (properties: Record<string, unknown>): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'graphic_design_link_click',
      ecommerce: {
        ...properties,
      },
    };

    push(data);

    resolve();
  });
export const clickField = (properties: Record<string, unknown>): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'field_click',
      ecommerce: {
        ...properties,
      },
    };

    push(data);

    resolve();
  });
export const clickSignUpPdp = (): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'signup_click',
    };

    push(data);

    resolve();
  });
export const clickTooltip = (properties: Record<string, unknown>): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'tooltip_click',
      ecommerce: {
        ...properties,
      },
    };

    push(data);

    resolve();
  });
export const signUpSuccess = (properties: Record<string, unknown>): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'sign_up_success',
      ecommerce: {
        ...properties,
      },
    };

    push(data);

    resolve();
  });
export const pdpUserProperties = (properties: Record<string, unknown>): Promise<void> =>
  new Promise(resolve => {
    const data = {
      event: 'track_user_properties',
      ecommerce: {
        ...properties,
      },
    };

    push(data);

    resolve();
  });
