import * as React from 'react';
import cn from 'classnames';
import { Fragment } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Transition, Popover } from '@headlessui/react';
import NestedPanel from 'styleguide/components/Nav/Tailwind/NestedPanel/NestedPanel';
import AppContext from 'contexts/AppContext/AppContext';
import { Taxon, Taxonomy } from 'contexts/AppContext/types';
import TilePanel from 'styleguide/components/Nav/Tailwind/TilePanel/TilePanel';
import HighlightPanel, {
  HighlightPanelProps,
} from 'styleguide/components/Nav/Tailwind/HighlightPanel/HighlightPanel';
import AccountMenu from 'styleguide/components/Nav/Tailwind/AccountMenu/AccountMenu';

import logo from 'assets/images/printivity-logo.png';
import { preloadComponent } from 'app/bundles/App/DynamicPages.imports-loadable';
import CartIcon from 'styleguide/components/Nav/CartIcon/CartIcon';
import HoverProvider, {
  useHoverContext,
} from 'styleguide/components/Nav/Tailwind/HoverProvider/HoverProvider';
import { ourStoryPath } from 'app/bundles/App/routes';
import { clickEmailUs } from 'api/gtm';
import { isProductPage } from 'libs/utils/pageUtils';

export interface NavigationItem {
  name: string;
  url: string;
  imageUrl?: string;
}

export interface NavigationCategory {
  name: string;
  items: NavigationItem[];
}

export interface NavigationMenu {
  name: string;
  type?: 'nested' | 'single' | 'grid' | 'hero' | 'link';
  items?: Taxon[];
  categories?: string[];
  secondary?: [{ name: string; link: string }];
  highlights?: HighlightPanelProps[];
  linkTarget?: string;
  preload?: string[];
}

const Nav = () => {
  const appContext = React.useContext(AppContext);
  const prodTaxonomy = appContext.store.taxonomies.find(elem => elem.name === 'Products');
  const taxon1: Taxon = prodTaxonomy.taxons[prodTaxonomy.taxons.length - 1];
  const taxon2: Taxon = prodTaxonomy.taxons[8];
  // const location = useLocation();
  // const matchPaths = [/cart/, /account(\/.*)*/];
  // const delayMessage: boolean = matchPaths.some(path => path.test(location.pathname));
  const delayMessage: boolean = false;

  const navTaxonomy: Taxonomy = appContext.store.taxonomies.find(elem => elem.name === 'Navbar');
  const serviceTaxonomy: Taxonomy = appContext.store.taxonomies.find(elem => elem.name === 'Services');
  const tempTaxonomy: Taxonomy = appContext.store.taxonomies.find(elem => elem.name === 'Temp');
  const marketing: Taxon = navTaxonomy.taxons.find(elem => elem.name === 'Marketing');
  const booksAndBooklets: Taxon[] = navTaxonomy.taxons.find(elem => elem.name === 'Books & Booklets').taxons;
  const boxes: Taxon = navTaxonomy.taxons.find(elem => elem.name === 'Boxes');
  const needTaxonomy: Taxonomy = appContext.store.taxonomies.find(
    (elem: Taxonomy) => elem.name === 'Shop By Need',
  );
  const location = useLocation();

  const nav: NavigationMenu[] = [
    {
      name: 'All Products',
      type: 'nested',
      items: navTaxonomy.taxons,
      preload: ['ProductDetailsPage'],
    },
    {
      name: 'Marketing Materials',
      type: 'grid',
      items: marketing.taxons.concat(boxes.taxons).concat(tempTaxonomy.taxons),
      categories: ['Marketing'],
      preload: ['ProductDetailsPage'],
    },
    {
      name: 'Books & Booklets',
      type: 'grid',
      items: booksAndBooklets,
      categories: ['Books', 'Booklets'],
      preload: ['ProductDetailsPage'],
    },
    {
      name: 'Shop By Need',
      type: 'grid',
      items: needTaxonomy?.taxons,
      preload: ['ProductDetailsPage'],
    },
    {
      name: 'Services',
      type: 'grid',
      items: serviceTaxonomy?.taxons,
      preload: ['RushCritical', 'FranchiseSolutions', 'BoxDesign'],
    },
    {
      name: 'Our Story',
      type: 'link',
      linkTarget: ourStoryPath,
      preload: ['OurStoryPage'],
    },
  ];

  const { hoveredItem, setHoveredItem } = useHoverContext();

  return (
    <div className="mx-auto max-w-base">
      <div className="flex gap-x-1">
        <div className="relative z-10 flex px-2 md:px-0">
          <div className="mb-1 flex flex-shrink-0 md:items-start lg:items-end">
            <RouterLink
              to="/"
              className="cursor-pointer no-underline"
              onMouseOver={() => preloadComponent('HomePage')}
            >
              <img className="block h-14 w-auto" src={logo} alt="Printivity" />
            </RouterLink>
          </div>
        </div>
        <div className="w-full">
          <div className="relative mt-0.5 flex h-12 justify-end md:pr-1 2xl:pr-0">
            <div
              className="paragraph-mobile mt-2 hidden space-x-6 text-gray-500 md:relative md:z-10 md:flex
          md:items-center"
            >
              <a
                className="paragraph-mobile inline-flex cursor-pointer items-center bg-shades-0
              text-gray-500 underline transition duration-300 ease-in-out hover:text-gray-300"
                href="tel:1-877-649-5463"
              >
                1-877-649-5463
              </a>
              <RouterLink
                to="/help-center"
                className="paragraph-mobile inline-flex cursor-pointer items-center bg-shades-0
              text-gray-500 no-underline transition duration-300 ease-in-out hover:text-gray-300"
                onMouseOver={() => preloadComponent('HelpCenterPage')}
              >
                Help Center
              </RouterLink>
              <RouterLink
                to="/contact-us"
                className={cn(
                  'text-gray-500 transition duration-300 ease-in-out hover:text-gray-300 ' +
                    'paragraph-mobile inline-flex cursor-pointer items-center bg-shades-0 no-underline',
                  'border-r border-solid border-default pr-6',
                )}
                onMouseOver={() => preloadComponent('ContactUsPage')}
                onClick={() =>
                  isProductPage(location.pathname, appContext.store.cmsPages) &&
                  clickEmailUs({ location: 'pdp_nav' })
                }
              >
                Contact Us
              </RouterLink>
              <AccountMenu />
              <CartIcon />
            </div>
          </div>
          <Transition show={hoveredItem !== null}>
            <Transition.Child
              as={Fragment}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-30"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-30"
              leaveTo="opacity-0"
            >
              <div
                className={cn(
                  delayMessage ? 'top-32' : 'top-20',
                  'fixed left-0 right-0 z-10 h-screen opacity-30 bg-gray-700 backdrop-blur-sm transition duration-150 ease-out',
                )}
              />
            </Transition.Child>
          </Transition>
          <Popover.Group
            className="relative items-end md:flex space-x-3 lg:space-x-6 md:pt-1 whitespace-nowrap"
            onMouseLeave={() => setHoveredItem(null)}
          >
            {nav.map((item: NavigationMenu, i: number) => (
              <Popover
                key={`${item.name}-${i}`}
                onMouseOver={() => {
                  if (item.preload) {
                    item.preload.forEach(elem => preloadComponent(elem));
                  }
                }}
              >
                {() =>
                  item.type === 'link' ? (
                    <RouterLink
                      data-cy={`navbar-${item.linkTarget.replace('/', '')}`}
                      to={item.linkTarget}
                      className="paragraph-medium-mobile inline-flex cursor-pointer items-center bg-shades-0 pb-0.5 text-default no-underline hover:text-gray-300"
                    >
                      {item.name}
                    </RouterLink>
                  ) : (
                    <>
                      <Popover.Button
                        className={cn(
                          hoveredItem === i
                            ? 'border-0 border-b-2 border-solid border-b-blue-700'
                            : 'transition-width after:m-auto after:block after:h-0.5 after:w-0 after:bg-blue-700 after:content-[""] hover:text-gray-300 hover:after:w-full',
                          'paragraph-medium-mobile group inline-block cursor-pointer items-center',
                        )}
                        onMouseEnter={() => setHoveredItem(i)}
                      >
                        <span>{item.name}</span>
                      </Popover.Button>
                      <Transition show={hoveredItem === i}>
                        <Transition.Child
                          as={Fragment}
                          enter="transition ease-out duration-150"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Popover.Panel
                            static
                            className="border-1 absolute -left-4 z-20 w-full transform rounded-b
                                 border border-gray-200 bg-white drop-shadow md:ml-0"
                          >
                            <div className="overflow-hidden">
                              <div className="min-h-50 relative flex justify-center px-1">
                                {item.type === 'nested' && (
                                  <HoverProvider>
                                    <NestedPanel
                                      items={item.items}
                                      taxon1={taxon1}
                                      taxon2={taxon2}
                                      onItemClick={() => setHoveredItem(null)}
                                    />
                                  </HoverProvider>
                                )}
                                {item.type === 'grid' && (
                                  <TilePanel
                                    name={item.name}
                                    items={item.items || []}
                                    categories={item.categories}
                                    onItemClick={() => setHoveredItem(null)}
                                  />
                                )}
                                {item.type === 'hero' && (
                                  <HighlightPanel
                                    items={item.highlights || []}
                                    onItemClick={() => setHoveredItem(null)}
                                  />
                                )}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition.Child>
                      </Transition>
                    </>
                  )
                }
              </Popover>
            ))}
          </Popover.Group>
        </div>
      </div>
    </div>
  );
};

export default Nav;
